import { Close } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  SxProps,
  Theme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PrivateFile } from "../../types/PrivateFile";
import { convertBytesToMbsOrKbs } from "../../utils/file";
import { getActivePath } from "../../utils/route";
import { PATH_SETTINGS } from "../Routes/paths";
import { useUpdateFilePasswordMutation } from "../../queries/file";
import { useTranslation } from "react-i18next";
import { PasswordToggleInput } from "./PasswordToggleInput";
import { useIsMobile } from "../../hooks/useIsMobile";

export interface SingleFileProps {
  file: PrivateFile;
  onRemove: (file: PrivateFile) => void;
  removeable: boolean;
  sx?: SxProps<Theme>;
}

const SingleBankFile = ({
  file,
  onRemove,
  removeable,
  sx = [],
}: SingleFileProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isMobile = useIsMobile();
  const [passwordEnabled, setPasswordEnabled] = useState(
    file.mimeType === "application/pdf"
  );
  const [password, setPassword] = useState(file.password || "");

  const { mutate: updateFilePassword } = useUpdateFilePasswordMutation();

  const handleSavePassword = () => {
    updateFilePassword({ file, password }, {});
  };

  useEffect(() => {
    if (passwordEnabled && password !== file.password) {
      handleSavePassword();
    }
  }, [password, passwordEnabled]);

  useEffect(() => {
    if (password === "" && passwordEnabled) {
      localStorage.setItem(`password-empty${file["@id"]}`, "true");
    } else {
      localStorage.removeItem(`password-empty${file["@id"]}`);
    }
    window.dispatchEvent(new Event("localStorageUpdated"));
  }, [password, passwordEnabled]);

  const handleRemove = () => {
    localStorage.removeItem(`password-empty${file["@id"]}`);
    window.dispatchEvent(new Event("localStorageUpdated"));
    onRemove(file);
  };

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Stack direction="column" spacing={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          {/* File name and size */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ flexGrow: 1 }}
          >
            <Box>
              <Typography variant="subtitle2" color="text.primary">
                {file.originalFilename}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {convertBytesToMbsOrKbs(file.size!)}
              </Typography>
            </Box>

            {/* Password toggle and input */}
            {file.mimeType === "application/pdf" && !isMobile && (
              <PasswordToggleInput
                passwordEnabled={passwordEnabled}
                setPasswordEnabled={setPasswordEnabled}
                password={password}
                setPassword={setPassword}
                t={t}
              />
            )}
          </Stack>

          {/* Remove button */}
          {removeable &&
            !getActivePath(
              PATH_SETTINGS.dataSourcesAddNewConnector,
              location.pathname
            ) && (
              <IconButton
                size="small"
                onClick={() => {
                  handleRemove();
                }}
              >
                <Close sx={{ color: "text.secondary" }} />
              </IconButton>
            )}
        </Stack>
        {/* Password toggle and input */}
        {file.mimeType === "application/pdf" && isMobile && (
          <PasswordToggleInput
            passwordEnabled={passwordEnabled}
            setPasswordEnabled={setPasswordEnabled}
            password={password}
            setPassword={setPassword}
            t={t}
          />
        )}
      </Stack>
    </Box>
  );
};

export default SingleBankFile;
