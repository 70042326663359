import { Button, Stack, Grid, Box, Typography } from "@mui/material";
import { useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import FandBIcon from "../Icons/FandBIcon";
import EcommerceIcon from "../Icons/EcommerceIcon";
import RetailIcon from "../Icons/RetailIcon";
import HealthIcon from "../Icons/HealthIcon";
import BtoBIcon from "../Icons/BtoBIcon";
import { useRevenueSegmentInfoPost } from "../../queries/businessInfo";
import ViolationError from "../../utils/violationError";

export type VerifyBusinessFormsType = {
  currentPage: number;
  handleChangePage: (new_page: number) => void;
};

export const RevenueSegmentOptions = [
  {
    id: 1,
    label: "pages.revenue_segment.food_beverage",
    value: "restaurants",
    icon: <FandBIcon />,
  },
  {
    id: 2,
    label: "pages.revenue_segment.ecommerce",
    value: "ecommerce",
    icon: <EcommerceIcon />,
  },
  {
    id: 3,
    label: "pages.revenue_segment.physical_retail",
    value: "retail",
    icon: <RetailIcon />,
  },
  {
    id: 4,
    label: "pages.revenue_segment.clinic_healthcase",
    value: "clinic",
    icon: <HealthIcon />,
  },
  {
    id: 5,
    label: "pages.revenue_segment.b2b",
    value: "b2b",
    icon: <BtoBIcon />,
  },
];

const ChooseRevenueSegments = ({
  handleChangePage,
  currentPage,
}: VerifyBusinessFormsType) => {
  const { t } = useTranslation(["translation", "countries"]);
  const { company, refetchCompany } = useAuth();

  const { mutate: verifyRevenueSegments } = useRevenueSegmentInfoPost();
  const [selected, setSelected] = useState<string[]>(
    company?.["revenueSegments"] ?? []
  );
  const [error, setError] = useState<string | null>(null);
  const handleSelection = (id: string) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleSubmit = () => {
    verifyRevenueSegments(
      {
        segments: selected,
      },
      {
        onError: (error) => {
          if (error instanceof ViolationError) {
            setError(`${error.defaultErrorMsg}`);
          } else {
            setError(`${error.message}`);
          }
        },
        onSuccess: (data) => {
          if (data.success) {
            refetchCompany();
            handleChangePage(currentPage + 1);
          } else {
            setError(t("error.unexpected"));
          }
        },
        onSettled: () => {
          handleChangePage(currentPage + 1);
        },
      }
    );
  };
  return (
    <>
      <Typography variant="h3" component="h1" sx={{ mb: 2 }}>
        {t("pages.choose_revenue_details.heading")}
      </Typography>
      <Typography variant="body2" component="h1" sx={{ mb: 2 }}>
        {t("pages.choose_revenue_details.title")}
      </Typography>
      <Grid container spacing={2} mt={4} mb={6}>
        {RevenueSegmentOptions.map((rev) => (
          <Grid item xs={6} key={rev.id}>
            <Box
              onClick={() => handleSelection(rev.value)}
              sx={
                selected.includes(rev.value)
                  ? {
                      height: "50px",
                      borderRadius: "8px",
                      textAlign: "left",
                      border: "1px solid #062F41",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      pl: "18px",
                    }
                  : {
                      height: "50px",
                      borderRadius: "8px",
                      textAlign: "left",
                      border: "1px solid #062F41",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      pl: "18px",
                      opacity: "25%",
                    }
              }
            >
              <Box>{rev.icon}</Box>
              <Typography
                sx={{
                  fontSize: { xs: "80%", sm: "100%" },
                }}
              >
                {t(rev.label)}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <Button
          id="revenue-detail-button"
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          size="large"
          disabled={selected.length === 0}
        >
          {t("global.continue")}
        </Button>
      </Stack>
    </>
  );
};

export default ChooseRevenueSegments;
