import { Box, Stack, TextField, Typography } from "@mui/material";
import { SwitchCustom as Switch } from "../../theme/overrides/Switch";

export const PasswordToggleInput = ({
  passwordEnabled,
  setPasswordEnabled,
  password,
  setPassword,
  t,
}: {
  passwordEnabled: boolean;
  setPasswordEnabled: (enabled: boolean) => void;
  password: string;
  setPassword: (password: string) => void;
  t: any;
}) => {
  return (
    <Stack direction="row" alignItems="center" spacing={{ xs: 5, sm: 20 }}>
      <Stack direction="column" alignItems={{ sm: "center" }} spacing={1}>
        <Typography variant="caption" color="text.secondary">
          {t("connectors.csv_connector.statement_password")}
        </Typography>
        <Switch
          checked={passwordEnabled}
          onChange={(e) => setPasswordEnabled(e.target.checked)}
          sx={{ transform: "scale(0.9)" }}
        />
      </Stack>
      <Box sx={{ width: "250px" }}>
        {passwordEnabled && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <TextField
              variant="standard"
              defaultValue="Normal"
              label={t("connectors.csv_connector.enter_statement_password")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                minWidth: { xs: "100px", sm: "200px" },
                "& .MuiInputLabel-root": { color: "text.secondary" },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
            />
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default PasswordToggleInput;
