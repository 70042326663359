import React from "react";
import { FieldProps } from "formik";
import { TextField, InputAdornment } from "@mui/material";
import { NumericFormat } from "react-number-format";

interface CurrencyInputProps extends FieldProps {
  label: string;
  min: number;
  maxTextLength: number;
  currency: string;
  currencyPosition: "start" | "end";
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  field,
  form,
  label,
  min,
  maxTextLength,
  currency,
  currencyPosition,
  ...props
}) => {
  const errorText =
    form.touched[field.name] && form.errors[field.name]
      ? form.errors[field.name]
      : "";

  return (
    <TextField
      {...field}
      {...props}
      label={label}
      error={!!errorText}
      helperText={errorText}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        [currencyPosition === "start" ? "startAdornment" : "endAdornment"]: (
          <InputAdornment position={currencyPosition}>
            {currency}
          </InputAdornment>
        ),
      }}
      inputProps={{
        min: min,
        maxLength: maxTextLength,
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        form.setFieldValue(field.name, value);
      }}
    />
  );
};

const NumericFormatCustom = (props: any) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
};

export default CurrencyInput;
