import { Stack, styled, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../hooks/useIsMobile";

const Dot = styled("span", {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "customColor",
})<{ active: boolean; customColor?: string }>(({ active, customColor }) => ({
  width: "100%",
  height: 4,
  backgroundColor: active ? customColor ?? "#05F1D8" : "#E0E0E0",
  borderRadius: "3px",
}));
const DotMobile = styled("span", {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active: boolean }>(({ active }) => ({
  height: 4,
  backgroundColor: active ? "#05F1D8" : "#E0E0E0",
  borderRadius: "3px",
}));

export enum OnboardingStepsEnum {
  SIGN_UP = "sign_up",
  CONNECT_DATA = "connect_data",
  VERIFY_BUSINESS = "verify_business",
  APPLICATION_OVERVIEW = "application_overview",
  FUNDING_REVIEW = "funding_review",
  GET_FUNDED = "get_funded",
}

type OnboardingStepType = {
  step?: OnboardingStepsEnum;
  sx?: SxProps<Theme>;
};

const OnboardingStep: React.FC<OnboardingStepType> = ({
  step = OnboardingStepsEnum.SIGN_UP,
  sx = [],
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const steps = {
    [OnboardingStepsEnum.SIGN_UP]: {
      id: 1,
      title: t("stepper.steps.sign_up"),
    },
    [OnboardingStepsEnum.CONNECT_DATA]: {
      id: 2,
      title: t("stepper.steps.connect_data"),
    },
    [OnboardingStepsEnum.VERIFY_BUSINESS]: {
      id: 3,
      title: t("stepper.steps.verify_business"),
    },
    [OnboardingStepsEnum.APPLICATION_OVERVIEW]: {
      id: 4,
      title: t("stepper.steps.application_overview"),
    },
    [OnboardingStepsEnum.FUNDING_REVIEW]: {
      id: 5,
      title: t("stepper.steps.funding_review"),
    },
    [OnboardingStepsEnum.GET_FUNDED]: {
      id: 6,
      title: t("stepper.steps.get_funded"),
    },
  };
  const selectedStepId = steps[step].id;

  return isMobile ? (
    <Stack
      alignItems="top"
      justifyContent="center"
      sx={{
        mt: "10px",
        display: "flex",
        flexDirection: "row",
        gap: "2px",
        width: "100%",
        ".MuiStack-item": {
          flexGrow: 1,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      }}
    >
      {Object.values(steps).map((item, index) => (
        <Stack key={index} width={item.id === selectedStepId ? "36%" : "18%"}>
          <DotMobile active={item.id <= selectedStepId} />
          {item.id <= selectedStepId ? (
            <Typography variant="h5" mt={2} sx={{ fontSize: "14px" }}>
              {t("stepper.heading")} {item.id}
            </Typography>
          ) : (
            <Typography
              variant="h5"
              mt={2}
              sx={{ color: "text.secondary", fontSize: "14px" }}
            >
              {t("stepper.heading")} {item.id}
            </Typography>
          )}
          {item.id === selectedStepId ? (
            <Typography variant="caption" sx={{ color: "text.secondary" }}>
              {item.title}
            </Typography>
          ) : (
            <Typography variant="caption" sx={{ color: "transparent" }}>
              {item.title}
            </Typography>
          )}
        </Stack>
      ))}
    </Stack>
  ) : (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={1.5}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      mb={2}
    >
      {Object.values(steps).map((item, index) => (
        <Stack key={index} sx={{ width: "16.5%" }}>
          {item.id === selectedStepId ? (
            <>
              <Dot active={item.id <= selectedStepId} customColor="#03A9F4" />
              <Typography variant="h5" mt={2} sx={{ color: "text.primary" }}>
                {t("stepper.heading")} {item.id}
              </Typography>
              <Typography variant="caption" sx={{ color: "text.secondary" }}>
                {item.title}
              </Typography>
            </>
          ) : (
            <>
              <Dot active={item.id <= selectedStepId} />
              <Typography variant="h5" mt={2} sx={{ color: "text.disabled" }}>
                {t("stepper.heading")} {item.id}
              </Typography>
              <Typography variant="caption" sx={{ color: "text.disabled" }}>
                {item.title}
              </Typography>
            </>
          )}
        </Stack>
      ))}
    </Stack>
  );
};

export default OnboardingStep;
